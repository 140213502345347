@charset "utf-8";
@import url('https://fonts.googleapis.com/css?family=Inter:400,500,600,700');

/* Colour Palette */
$primary: #ff395c;
$secondary: #2fa2d1;
$blackish: #2c2c2c;
$gray: #B8B8B8;

/* Colour Shades */
$primary-light-shade: #ffc2cd;
$primary-shade: #B31532;
$secondary-shade: #1A7599;
$blackish-shade: #5e5e5e;
$gray-shade: #dbdbdb;

/* Colour Rainbow */
$rainbow-red: #E63253;
$rainbow-red-shade: #d6314f;

$rainbow-dark-red: #BF2A45;
$rainbow-dark-red-shade: #b22a43;

$rainbow-yellow: #E6BE49;
$rainbow-yellow-shade: #ddb84a;

$rainbow-dark-yellow: #E6973E;
$rainbow-dark-yellow-shade: #db9340;

$rainbow-blue: #1CABE6;
$rainbow-blue-shade: #1fa1d8;

$rainbow-purple: #981CA6;
$rainbow-purple-shade: #8b1c97;

$rainbow-dark-purple: #5E1166;
$rainbow-dark-purple-shade: #4e1953;

/* Define colours */
$text: $blackish;
$title-color: $blackish !important;
$table-color: $blackish !important;
$primary: $primary;

/* Settings */
$family-sans-serif: "Inter", sans-serif;
$navbar-item-hover-background-color: #ffffff;
$navbar-item-active-background-color: #ffffff;
$navbar-tab-hover-background-color: #ffffff;
$navbar-tab-active-background-color: #ffffff;
$navbar-dropdown-background-color: #ffffff;
$navbar-dropdown-item-hover-background-color: #ffffff;
$navbar-dropdown-item-active-background-color: #ffffff;
$navbar-dropdown-item-hover-background-color: #ffffff;
$navbar-tab-hover-background-color: #ffffff;
$pagination-current-background-color: $primary;
$pagination-current-border-color: $primary;
$tabs-link-color: $blackish;
$tabs-link-hover-border-bottom-color: $primary;
$tabs-link-hover-color: $primary;
$tabs-link-active-border-bottom-color: $primary;
$tabs-link-active-color: $primary;

@import "../../../node_modules/bulma/bulma.sass";
@import "bulma-switch.min.css";
@import "bulma-divider.min.css";
@import "bulma-checkradio.min.css";
@import "bulma-timeline.min.css";



/* ===================== */
/* ===== General ======= */
/* ===================== */
.card header {
    cursor: pointer;
}
@keyframes blink {
    50% { color: transparent }
}
.loader-dot { animation: 1s blink infinite }
.loader-dot:nth-child(2) { animation-delay: 250ms }
.loader-dot:nth-child(3) { animation-delay: 500ms }

table.tableList > tbody > tr:hover {
    background-color: $primary-light-shade;
}
table.tableList > tbody > tr.noHover:hover {
    background-color: transparent;
}
.has-text-secondary {
    color: $secondary;
}

.overflow-auto {
    overflow: auto;
}

.float-left {
    float: left;
}

/* Jackpot with subtitle */
.jackpot-size {
    font-size: 5rem !important;
    line-height: 5rem;
}

.jackpot-subtitle {
    font-size: 3rem !important;
}

.clearfix {
    overflow: auto;
}



/* ===================== */
/* ===== Table ========= */
/* ===================== */
.table tr.is-selected {
    background-color: $primary-light-shade;
    color: $blackish;
}



/* ===================== */
/* ===== Main Menu ===== */
/* ===================== */
@media screen and (max-width: 1023px) {
    #navbar-mobile > .section > .container > .columns > .column {
        width: 25%;
        float: left;
        font-size: 0.9rem;
    }

    #navbar-mobile > .section > .container > .columns > .column > a.is-active {
        color: $primary;
        opacity: 0.75;
    }
}

@media screen and (min-width: 1024px) {
    /* Navbar */
    .navbar.is-white .navbar-start > a.navbar-item:focus,
    .navbar.is-white .navbar-start > a.navbar-item:hover,
    .navbar.is-white .navbar-start > a.navbar-item.is-active,
    .navbar.is-white .navbar-start .navbar-link:focus,
    .navbar.is-white .navbar-start .navbar-link:hover,
    .navbar.is-white .navbar-start .navbar-link.is-active,
    .navbar.is-white .navbar-end > a.navbar-item:focus,
    .navbar.is-white .navbar-end > a.navbar-item:hover,
    .navbar.is-white .navbar-end > a.navbar-item.is-active,
    .navbar.is-white .navbar-end .navbar-link:focus,
    .navbar.is-white .navbar-end .navbar-link:hover,
    .navbar.is-white .navbar-end .navbar-link.is-active,
    .navbar.is-white .navbar-item.has-dropdown:focus .navbar-link,
    .navbar.is-white .navbar-item.has-dropdown:hover .navbar-link,
    .navbar.is-white .navbar-item.has-dropdown.is-active .navbar-link {
        background-color: #ffffff !important;
        color: $primary;
    }

    .navbar.is-white .navbar-start .navbar-link:focus::after,
    .navbar.is-white .navbar-start .navbar-link:hover::after,
    .navbar.is-white .navbar-start .navbar-link.is-active::after,
    .navbar.is-white .navbar-end .navbar-link:focus::after,
    .navbar.is-white .navbar-end .navbar-link:hover::after,
    .navbar.is-white .navbar-end .navbar-link.is-active::after,
    .navbar.is-white .navbar-item.has-dropdown:focus .navbar-link::after,
    .navbar.is-white .navbar-item.has-dropdown:hover .navbar-link::after,
    .navbar.is-white .navbar-item.has-dropdown.is-active .navbar-link::after {
        transform: rotate(-225deg);
        top: 60%;
    }

    .navbar-dropdown {
        border: 1px solid #f5f5f5;
        border-top: none;
        top: 99.7%;
    }

    .navbar-dropdown .lotto-card {
        width: 200px;
        height: 130px;
    }

    /* Navbar - end */
    .navbar-dropdown-right {
        left: unset;
        right: 0 !important;
    }

    .navbar-link-circle:not(.is-arrowless)::after {
        display: none;
    }

    .navbar-link-circle i {
        border: 1px solid $blackish-shade;
        padding: 10px 10px 5px 10px;
        border-radius: 50%;
    }

    .navbar.is-white .navbar-item.has-dropdown:focus .navbar-link-circle i,
    .navbar.is-white .navbar-item.has-dropdown:hover .navbar-link-circle i,
    .navbar.is-white .navbar-item.has-dropdown.is-active .navbar-link-circle i {
        border: 1px solid $primary;
    }

    .navbar-dropdown a.navbar-item:hover,
    .navbar-dropdown a.navbar-item:focus,
    .navbar-dropdown a.navbar-item.is-active {
        color: $primary;
    }

    .login-button {
        font-size: 14px;
    }
}



/* ===================== */
/* ===== Button ======== */
/* ===================== */
.cl-button-loader {
    width: 20px;
    height: 20px;
    margin-left: 10px;
    border-radius: 50%;
    display: inline-block;
    border-top: 3px solid #FFF;
    border-right: 3px solid transparent;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
}
@keyframes rotation {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.cl-button-loader-primary {
    width: 20px;
    height: 20px;
    margin-left: 10px;
    border-radius: 50%;
    display: inline-block;
    border-top: 3px solid $primary;
    border-right: 3px solid transparent;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
}

button.is-disabled {
    color: $gray !important;
    background-color: $gray-shade !important;
    border: 1px solid #ffffff !important;
}
button.is-disabled:hover {
    color: $gray !important;
    background-color: $gray-shade !important;
    border: 1px solid #ffffff !important;
}

.button.is-secondary {
    color: #ffffff;
    background-color: $secondary;
    border-color: $secondary;
}
.button.is-secondary:hover {
    color: #ffffff;
    background-color: $secondary-shade;
    border-color: $secondary-shade;
}

@media screen and (max-width: 1229px) {
    .button.is-game-setter {
        font-size: 0.6rem;
    }
}
@media screen and (min-width: 1230px) {
    .button.is-game-setter {
        font-size: 0.9rem;
    }
}
.button.is-game-setter {
    display: block;
    float: left;

    background-color: #ffffff;
    color: $blackish;

    border: 0;
    font-weight: 600;
    padding: 5px 10px 5px 10px;
}
.button.is-game-setter:hover, .button.is-game-setter.is-active {
    background-color: $secondary;
    color: #ffffff;

    text-decoration: underline;
    cursor: pointer;

    border-radius: 20px;
    -moz-border-radius: 20px;
    -webkit-border-radius: 20px;
}
.button.is-game-setter.is-disabled {
    background-color: #ffffff !important;
    text-decoration: none !important;
    cursor: default !important;

    border-radius: 20px;
    -moz-border-radius: 20px;
    -webkit-border-radius: 20px;
}

.button.lotto-button {
    color: #ffffff;
    background-color: transparent;
    border-color: #ffffff !important;
}

.button.is-primary.has-border {
    border-color: #ffffff !important;
}

.button.is-white-with-primary-text {
    color: $primary;
    background-color: #ffffff;
}
.button.is-white-with-primary-text:hover {
    color: $primary;
    background-color: #ffffff;
}

.is-white-with-gray-text {
    color: $gray;
    border-color: $gray;
    background-color: #ffffff;
}
.is-white-with-gray-text:hover {
    color: $gray-shade;
    border-color: $gray-shade;
    background-color: #ffffff;
}

.button.is-transparent-with-gray-color {
    color: $blackish;
    background-color: transparent !important;
    border-color: $blackish !important;
}
.button.is-transparent-with-gray-color:hover {
    color: $blackish-shade;
    background-color: transparent !important;
    border-color: $blackish-shade !important;
}

/* Rainbow buttons */
.button.is-rainbow-redish {
    color: #ffffff;
    background-color: $rainbow-red;
    border: 1px solid #ffffff;
}
.button.is-rainbow-redish:hover {
    background-color: $rainbow-red-shade;
    border: 1px solid #ffffff;
}

.button.is-rainbow-yellow {
    color: #ffffff;
    background-color: $rainbow-yellow;
    border: 1px solid #ffffff;
}
.button.is-rainbow-yellow:hover {
    background-color: $rainbow-yellow-shade;
    border: 1px solid #ffffff;
}

.button.is-rainbow-blueish {
    color: #ffffff;
    background-color: $rainbow-blue;
    border: 1px solid #ffffff;
}
.button.is-rainbow-blueish:hover {
    background-color: $rainbow-blue-shade;
    border: 1px solid #ffffff;
}

.button.is-rainbow-red {
    color: #ffffff;
    background-color: $rainbow-dark-red;
    border: 1px solid #ffffff;
}
.button.is-rainbow-red:hover {
    background-color: $rainbow-dark-red-shade;
    border: 1px solid #ffffff;
}

.button.is-rainbow-yellow-dark {
    color: #ffffff;
    background-color: $rainbow-dark-yellow;
    border: 1px solid #ffffff;
}
.button.is-rainbow-yellow-dark:hover {
    background-color: $rainbow-dark-yellow-shade;
    border: 1px solid #ffffff;
}

.button.is-rainbow-purple {
    color: #ffffff;
    background-color: $rainbow-purple;
    border: 1px solid #ffffff;
}
.button.is-rainbow-purple:hover {
    background-color: $rainbow-purple-shade;
    border: 1px solid #ffffff;
}

/* Borders */
.border-white-bottom {
    border-bottom: 1px solid #ffffff;
}

.border-radius-10 {
    border-radius: 10px;
}



/* =========================== */
/* ===== Backgrounds ========= */
/* =========================== */
.has-background-redish {
    background-color: $rainbow-red;
}

.has-background-yellow {
    background-color: $rainbow-yellow;
}

.has-background-blueish {
    background-color: $rainbow-blue;
}

.has-background-red {
    background-color: $rainbow-dark-red;
}

.has-background-yellow-dark {
    background-color: $rainbow-dark-yellow;
}

.has-background-purple {
    background-color: $rainbow-purple;
}



/* ===================== */
/* ===== Color ========= */
/* ===================== */
.has-redish {
    color: $rainbow-red !important;
}

.has-yellow {
    color: $rainbow-yellow !important;
}

.has-blueish {
    color: $rainbow-blue !important;
}

.has-red {
    color: $rainbow-dark-red !important;
}

.has-yellow-dark {
    color: $rainbow-dark-yellow !important;
}

.has-purple {
    color: $rainbow-purple !important;
}



/* ===================== */
/* ===== Links ========= */
/* ===================== */
.cl-link-standard-loader {
    width: 13px;
    height: 13px;
    margin-left: 10px;
    border-radius: 50%;
    display: inline-block;
    border-top: 3px solid $blackish;
    border-right: 3px solid transparent;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
}

.link-standard {
    color: $blackish !important;
    text-decoration: underline !important;
    font-weight: 600 !important;
    background-color: transparent;
    border: 0;
    cursor: pointer;
}
.link-standard:hover {
    color: $blackish-shade !important;
}

a.link-white {
    color: #ffffff !important;
    text-decoration: underline !important;
    font-weight: 600 !important
}
a.link-white:hover {
    color: #ffffff !important;
}

button.is-inactive, a.is-inactive {
    color: $gray-shade !important;
    cursor: default;

    text-decoration: underline !important;
    font-weight: 600 !important;
    background-color: transparent;
    border: 0;
}
button.is-inactive:hover, a.is-inactive:hover {
    color: $gray-shade !important;
    cursor: default;
}

.menu-list a {
    text-decoration: inherit !important;
    // font-weight: inherit !important;
}
.menu-list a:hover {
    text-decoration: underline !important;
    // background-color: transparent !important;
}



/* ===================== */
/* ===== Forms ========= */
/* ===================== */
.switch[type=checkbox].is-info:checked+label::before,
.switch[type=checkbox].is-info:checked+label:before {
    background: $secondary;
}

/* Dots */
.round-redish-dot {
    display: block;
    float: left;

    width: 20px;
    height: 20px;

    border-radius: 50%;
    -moz-border-radius:50%;
    -webkit-border-radius:50%;

    background-color: $rainbow-red;
    color: $rainbow-red;
    text-align: center;

    font-size: 16px;
    margin-right: 10px;
}

.round-yellow-dot {
    display: block;
    float: left;

    width: 20px;
    height: 20px;

    border-radius: 50%;
    -moz-border-radius:50%;
    -webkit-border-radius:50%;

    background-color: $rainbow-yellow;
    color: $rainbow-yellow;
    text-align: center;

    font-size: 16px;
    margin-right: 10px;
}

.round-blueish-dot {
    display: block;
    float: left;

    width: 20px;
    height: 20px;

    border-radius: 50%;
    -moz-border-radius:50%;
    -webkit-border-radius:50%;

    background-color: $rainbow-blue;
    color: $rainbow-blue;
    text-align: center;

    font-size: 16px;
    margin-right: 10px;
}

.round-yellow-dark-dot {
    display: block;
    float: left;

    width: 20px;
    height: 20px;

    border-radius: 50%;
    -moz-border-radius:50%;
    -webkit-border-radius:50%;

    background-color: $rainbow-dark-yellow;
    color: $rainbow-dark-yellow;
    text-align: center;

    font-size: 16px;
    margin-right: 10px;
}

.round-purple-dot {
    display: block;
    float: left;

    width: 20px;
    height: 20px;

    border-radius: 50%;
    -moz-border-radius:50%;
    -webkit-border-radius:50%;

    background-color: $rainbow-purple;
    color: $rainbow-purple;
    text-align: center;

    font-size: 16px;
    margin-right: 10px;
}

/* Empty numbers */
.none-selected-empty {
    border-radius: 50%;
    -moz-border-radius:50%;
    -webkit-border-radius:50%;

    width: 36px;
    height: 36px;
    padding: 5px;

    background-color: #ffffff;
    border: 1px solid $blackish-shade;
    color: #ffffff;
    text-align: center;

    font-size: 16px;

    margin-right: 10px;

    display: block;
    float: left;
}

/* Selected and Non-selected numbers */
.none-selected-number, .none-selected-number-disabled, .selected-number {
    display: block;
    float: left;

    border-radius: 50%;
    -moz-border-radius:50%;
    -webkit-border-radius:50%;

    width: 36px;
    height: 36px;
    padding: 5px;

    font-size: 16px;
    margin-right: 10px;

    text-align: center;
}

@media screen and (max-width: 679px) {
    .is-mobile-screen-adaptive .none-selected-number,
    .is-mobile-screen-adaptive .selected-number,
    .none-selected-number.is-mobile-screen-adaptive,
    .selected-number.is-mobile-screen-adaptive {
        cursor: pointer;

        font-size: 14px;

        width: 27px;
        height: 27px;
        padding: 2px;

        margin-right: 7px;
    }
}

@media screen and (min-width: 680px) {
    .is-mobile-screen-adaptive .none-selected-number,
    .is-mobile-screen-adaptive .selected-number,
    .none-selected-number.is-mobile-screen-adaptive,
    .selected-number.is-mobile-screen-adaptive {
        cursor: pointer;

        width: 36px;
        height: 36px;
        padding: 5px;

        margin-right: 10px;
    }
}

.none-selected-number {
    background-color: #ffffff;
    border: 1px solid $blackish-shade;
    color: $blackish-shade;
}

.none-selected-number-disabled {
    background-color: #ffffff;
    border: 1px solid $secondary;
    color: $secondary;
}

.selected-number,
.none-selected-number:hover {
    background-color: $secondary;
    border: 1px solid $secondary;
    color: #ffffff;
}



/* ============================== */
/* ===== Game & Watch draws ===== */
/* ============================== */
/* Game & Watch draws :: Header - Left */
@media screen and (min-width: 1024px) {
    .time {
        height: 4.2rem;
    }
}
@media screen and (max-width: 1023px) {
    .time {
        height: 3.8rem;
    }
}
.time {
    border-radius: 50%;
    -moz-border-radius:50%;
    -webkit-border-radius:50%;

    width: 4.2rem;
    padding: 0.2rem;
    /* padding-top: 9px; */

    background-color: #ffffff;
    color: #333333;
    text-align: center;

    font-size: 16px;

    margin-right: 10px;

    display: block;
    float: left;
}

.time > span.time-number {
    display: block;
    font-size: 2.2rem;
    font-weight: bold;
    line-height: 2rem;
    padding-top: 0.4rem;
}

.time > span.time-label {
    display: block;
    font-size: 0.8rem;
}

.divider {
    font-size: 2.5rem;
    font-weight: bold;
}

/* Game :: Header - Right */
@media screen and (max-width: 1023px) {
    #game-settings-block {
        text-align: center !important;
    }
}

@media screen and (min-width: 1024px) {
    #game-settings-block {
        text-align: right !important;
        margin-right: 20px;
    }
}

#game-settings {
    text-align: left !important;
}

#game-settings-mobile {
    float: left;
    width: 100%;
}

#price-per-game {
    position: absolute;
    top: 10px;
    right: 10px;
}

@media screen and (max-width: 1229px) {
    .choose-lucky-dip-column {
        flex-basis: unset !important;
        flex-grow: unset !important;
        width: 30% !important;
    }

    .or {
        float: left;
        font-size: 0.8rem;
    }
}

.edit-numbers {
    display: block;
    float: left;
    margin-left: 20px;
    text-decoration: underline;
    margin-top: 5px;
}

.delete-numbers {
    display: block;
    float: left;
    margin-left: 20px;
    text-decoration: underline;
    margin-top: 5px;
}

.number-picker-container {
    overflow: auto;
}

/* Watch draws :: Header - Right */
.draws-number {
    display: block;
    float: left;

    border-radius: 50%;
    -moz-border-radius:50%;
    -webkit-border-radius:50%;

    padding: 5px;
    margin-right: 10px;

    text-align: center;

    border: 1px solid #ffffff;
    color: #ffffff;
}

.cl-loader {
    border-radius: 50%;
    display: inline-block;
    border-top: 3px solid #FFF;
    border-right: 3px solid transparent;
    box-sizing: border-box;
    animation: rotation 2s linear infinite;
}

@keyframes rotation {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

@media screen and (max-width: 679px) {
    .draws-number {
        width: 47px;
        height: 47px;
        font-size: 23px;
    }

    .cl-loader {
        width: 35px;
        height: 35px;
    }
}

@media screen and (min-width: 680px) and (max-width: 1023px) {
    .draws-number {
        width: 62px;
        height: 62px;
        font-size: 33px;
    }

    .cl-loader {
        width: 50px;
        height: 50px;
    }
}

@media screen and (min-width: 1024px) {
    .draws-number {
        width: 80px;
        height: 80px;
        font-size: 46px;
    }

    .cl-loader {
        width: 68px;
        height: 68px;
    }
}

table.watch-the-draw-result-table {
    background-color: transparent;
}

table.watch-the-draw-result-table > thead > tr > th,
table.watch-the-draw-result-table > tbody > tr > th,
table.watch-the-draw-result-table > tbody > tr > td {
    color: #ffffff;
    border-bottom: 1px dashed #ffffff;
}

table.watch-the-draw-result-table > tbody > tr > th {
    width: 130px;
    border-bottom: 1px dashed #ffffff;
}

.blinking {
    animation: blink-jackpot 3s steps(50, start) infinite;
    -o-animation: blinking-jackpot 3s steps(50, start) infinite;  /* Opera 12+ */
    -moz-animation: blinking-jackpot 3s steps(50, start) infinite;  /* Fx 5+ */
    -webkit-animation: blink-jackpot 3s steps(50, start) infinite;
}

@keyframes blink-jackpot {
    0%, 49% {
        background-color: $primary-light-shade;
    }
    50%, 100% {
        // background-color: #e50000;
    }
}
@-webkit-keyframes blink-jackpot {
    0%, 49% {
        background-color: $primary-light-shade;
    }
    50%, 100% {
        // background-color: #e50000;
    }
  }



/* ========================= */
/* ===== Settings page ===== */
/* ========================= */
.content ul {
    list-style: none !important;
    margin-left: 0em !important;
    margin-top: 0em !important;
}

@media screen and (max-width: 1023px) {
    #nav-mobile-level-one ul li a {
        font-weight: bold;
        color: $blackish-shade;
    }

    #nav-mobile-level-two ul li a {
        padding: 3px 10px;
        margin: 12px;
        font-weight: bold;
        color: $blackish-shade;
    }

    #nav-mobile-level-one ul li.is-active a {
        font-weight: bold;
        color: $blackish;
        border-bottom-color: $primary;
    }

    #nav-mobile-level-two ul,
    #nav-mobile-level-two a,
    #nav-mobile-level-two ul li.is-active a {
        border-bottom: none;
    }

    #nav-mobile-level-two ul li.is-active a {
        font-weight: bold;
        color: $blackish;
        background-color: $gray;
        padding: 3px 10px;
        margin: 12px;
    }

    #nav-mobile tbody > tr > :nth-child(1){
        font-weight: normal !important;
    }
}



/* ===================== */
/* ===== Lotto cards === */
/* ===================== */
.lotto-card-redish {
    background-color: $rainbow-red;
    color: #ffffff;
}
.lotto-card-redish:not(.nohover):hover {
    background-color: $rainbow-red-shade;
}
.lotto-card-redish .button.lotto-button:hover {
    background-color: $rainbow-red-shade;
}

.lotto-card-yellow {
    background-color: $rainbow-yellow;
    color: #ffffff;
}
.lotto-card-yellow:not(.nohover):hover {
    background-color: $rainbow-yellow-shade;
}
.lotto-card-yellow .button.lotto-button:hover {
    background-color: $rainbow-yellow-shade;
}

.lotto-card-blueish {
    background-color: $rainbow-blue;
    color: #ffffff;
}
.lotto-card-blueish:not(.nohover):hover {
    background-color: $rainbow-blue-shade;
}
.lotto-card-blueish .button.lotto-button:hover {
    background-color: $rainbow-blue-shade;
}

.lotto-card-red {
    background-color: $rainbow-dark-red;
    color: #ffffff;
}
.lotto-card-red:not(.nohover):hover {
    background-color: $rainbow-dark-red-shade;
}
.lotto-card-red .button.lotto-button:hover {
    background-color: $rainbow-dark-red-shade;
}

.lotto-card-yellow-dark {
    background-color: $rainbow-dark-yellow;
    color: #ffffff;
}
.lotto-card-yellow-dark:not(.nohover):hover {
    background-color: $rainbow-dark-yellow-shade;
}
.lotto-card-yellow-dark .button.lotto-button:hover {
    background-color: $rainbow-dark-yellow-shade;
}

.lotto-card-purple {
    background-color: $rainbow-purple;
    color: #ffffff;
}
.lotto-card-purple:not(.nohover):hover {
    background-color: $rainbow-purple-shade;
}
.lotto-card-purple .button.lotto-button:hover {
    background-color: $rainbow-purple-shade;
}

.lotto-card-purple-dark {
    background-color: $rainbow-dark-purple;
    color: #ffffff;
}
.lotto-card-purple-dark:not(.nohover):hover {
    background-color: $rainbow-dark-purple-shade;
}



/* ================================= */
/* ===== Quick Lucky Dip Block ===== */
/* ================================= */
@media screen and (min-width: 1024px) {
    .quick-lucky-dip-block .continue-to-play {
        border-left: 1px solid #ffffff;
    }
}

.quick-lucky-dip-block {
    border-radius: 20px;
}

.quick-lucky-dip-block .tickets-subtract-or-add {
    display: block;
    float: left;

    border-radius: 50%;
    -moz-border-radius:50%;
    -webkit-border-radius:50%;

    width: 36px;
    height: 36px;
    padding: 5px;
    font-size: 20px;
    margin-right: 10px;

    border: 1px solid #ffffff;
    color: #ffffff;
    text-align: center;
    cursor: pointer;
}

.quick-lucky-dip-block .tickets-subtract-or-add:hover {
    background-color: $primary-shade;
}

.quick-lucky-dip-block .number-of-tickets {
    font-size: 4rem;
    font-weight: bold;
}


/* ====================================== */
/* ===== Winners and Good Causes ======== */
/* ====================================== */
.winner-card {
    height: 190px;
}
.causes-card {
    height: 550px;
}

/* =========================== */
/* ===== Social icons ======== */
/* =========================== */
.social-icon-link i,
.social-icon-link svg {
    color: $blackish;
}

.social-icon-link i:hover,
.social-icon-link svg:hover {
    color: $blackish-shade;
}



/* ===================== */
/* ===== Footer ======== */
/* ===================== */
.give-feedback-section {
    border-top: 1px solid #ffffff;
}



/* ======================== */
/* ===== Countdown ======== */
/* ======================== */
.countdownTimer > .timeBlock {
	font-size: 25px;
    font-weight: bold;
}
.countdownTimer > .timeSplitter {
	font-size: 25px;
    padding: 4px;
    font-weight: bold;
}
.countdownTimer > .timeBlock {
	border: 1px solid #dbdbdb;
    border-radius: 6px;
    padding: 7px;
    background-color: #f9f9f9;
}



/* ======================== */
/* ===== Preloader ======== */
/* ======================== */
.preloader {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999999999;
    width: 100%;
    height: 100%;
    background-color: #fff;
    overflow: hidden;
}

.preloader-inner {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.preloader-icon {
    width: 100px;
    height: 100px;
    display: inline-block;
    padding: 0px;
}

.preloader-icon span {
    position: absolute;
    display: inline-block;
    width: 100px;
    height: 100px;
    border-radius: 100%;
    background: #ff6b81;
    -webkit-animation: preloader-fx 1.6s linear infinite;
    animation: preloader-fx 1.6s linear infinite;
}

.preloader-icon span:last-child {
    animation-delay: -0.8s;
    -webkit-animation-delay: -0.8s;
}

@keyframes preloader-fx {
    0% {
      -webkit-transform: scale(0, 0);
              transform: scale(0, 0);
      opacity: 0.5;
    }
    100% {
      -webkit-transform: scale(1, 1);
              transform: scale(1, 1);
      opacity: 0;
    }
}

@-webkit-keyframes preloader-fx {
    0% {
      -webkit-transform: scale(0, 0);
      opacity: 0.5;
    }
    100% {
      -webkit-transform: scale(1, 1);
      opacity: 0;
    }
}



/* ======================== */
/* ===== Progress ========= */
/* ======================== */
.progress.is-redish::-webkit-progress-value {
    background-color: rgba($color: $rainbow-red, $alpha: 1.0)
}
.progress.is-redish::-moz-progress-bar {
    background-color:rgba($color: $rainbow-red, $alpha: 1.0)
}
.progress.is-redish::-ms-fill {
    background-color:rgba($color: $rainbow-red, $alpha: 1.0)
}
.progress.is-redish:indeterminate {
    background-image: linear-gradient(to right, rgba($color: $rainbow-red, $alpha: 1.0) 30%, #ededed 30%);
}

.progress.is-yellow::-webkit-progress-value {
    background-color: rgba($color: $rainbow-yellow, $alpha: 1.0)
}
.progress.is-yellow::-moz-progress-bar {
    background-color:rgba($color: $rainbow-yellow, $alpha: 1.0)
}
.progress.is-yellow::-ms-fill {
    background-color:rgba($color: $rainbow-yellow, $alpha: 1.0)
}
.progress.is-yellow:indeterminate {
    background-image: linear-gradient(to right, rgba($color: $rainbow-yellow, $alpha: 1.0) 30%, #ededed 30%);
}

.progress.is-blueish::-webkit-progress-value {
    background-color: rgba($color: $rainbow-blue, $alpha: 1.0)
}
.progress.is-blueish::-moz-progress-bar {
    background-color:rgba($color: $rainbow-blue, $alpha: 1.0)
}
.progress.is-blueish::-ms-fill {
    background-color:rgba($color: $rainbow-blue, $alpha: 1.0)
}
.progress.is-blueish:indeterminate {
    background-image: linear-gradient(to right, rgba($color: $rainbow-blue, $alpha: 1.0) 30%, #ededed 30%);
}

.progress.is-red::-webkit-progress-value {
    background-color: rgba($color: $rainbow-dark-red, $alpha: 1.0)
}
.progress.is-red::-moz-progress-bar {
    background-color:rgba($color: $rainbow-dark-red, $alpha: 1.0)
}
.progress.is-red::-ms-fill {
    background-color:rgba($color: $rainbow-dark-red, $alpha: 1.0)
}
.progress.is-red:indeterminate {
    background-image: linear-gradient(to right, rgba($color: $rainbow-dark-red, $alpha: 1.0) 30%, #ededed 30%);
}

.progress.is-yellow-dark::-webkit-progress-value {
    background-color: rgba($color: $rainbow-dark-yellow, $alpha: 1.0)
}
.progress.is-yellow-dark::-moz-progress-bar {
    background-color:rgba($color: $rainbow-dark-yellow, $alpha: 1.0)
}
.progress.is-yellow-dark::-ms-fill {
    background-color:rgba($color: $rainbow-dark-yellow, $alpha: 1.0)
}
.progress.is-yellow-dark:indeterminate {
    background-image: linear-gradient(to right, rgba($color: $rainbow-dark-yellow, $alpha: 1.0) 30%, #ededed 30%);
}

.progress.is-purple::-webkit-progress-value {
    background-color: rgba($color: $rainbow-purple, $alpha: 1.0)
}
.progress.is-purple::-moz-progress-bar {
    background-color:rgba($color: $rainbow-purple, $alpha: 1.0)
}
.progress.is-purple::-ms-fill {
    background-color:rgba($color: $rainbow-purple, $alpha: 1.0)
}
.progress.is-purple:indeterminate {
    background-image: linear-gradient(to right, rgba($color: $rainbow-purple, $alpha: 1.0) 30%, #ededed 30%);
}



/* ============================= */
/* ===== Fade In / Out ========= */
/* ============================= */
@media screen and (max-width: 1023px) {
    .fadeOut {
        display: none;
    }
    .fadeIn {

    }
}

@media screen and (min-width: 1024px) {
    .fadeOut {
        opacity:0;
        width:0;
        height:0;
    }
    .fadeIn {
        opacity:1;
        transition: width 0.1s, height 0.1s, opacity 0.4s;
    }
}



/* ================================= */
/* ===== How To Play Popup ========= */
/* ================================= */
.how-to-play-box img {
    border: 1px solid #1e88b3;
}

.how-to-play-box p {
    color: $blackish;
}